<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'users' }">
          Home
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-2 font-weight-bold my-6">
        {{ displayName }}
      </div>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-text>
            <v-form ref="form" class="user-form">
              <label class="text-field-label required"> Full Name </label>
              <v-text-field
                v-model="form.full_name"
                flat
                solo
                label="Full Name"
                required
                class="mt-2"
                :loading="form.$busy"
                :error-messages="form.$getError('full_name')"
              />

              <label class="text-field-label required">Email</label>

              <v-text-field
                v-model="form.email"
                flat
                solo
                label="Email"
                required
                class="mt-2"
                :loading="form.$busy"
                :error-messages="form.$getError('email')"
              />

              <div class="mt-4">
                <v-btn
                  color="primary"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="updateProfiletails"
                  :loading="form.$busy"
                >
                  Update
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiCheck, mdiChevronLeft } from '@mdi/js'

import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Admin from '@/models/Admin'
import Form from '@/utils/form'

export default {
  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,

      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
      },

      snackbar: {
        show: false,
        message: null,
        color: '',
      },

      form: new Form({
        id: '',
        full_name: '',
        email: '',
      }),
    }
  },

  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
    }),

    displayName() {
      return this.authUser.full_name
    },
  },

  created() {
    this.propulateForm()
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser',
      bypassSubscription: 'user/bypassSubscription',
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      setSelectedUserAvatar: 'user/setSelectedUserAvatar',
      userAvatarRemoved: 'user/userAvatarRemoved',
      setBypassLoading: 'user/setBypassLoading',
      updateAuthUser: 'auth/updateAuthUser',
    }),

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    propulateForm() {
      this.form = new Form({ ...this.form, ...this.authUser })
    },

    async updateProfiletails() {
      this.form.$clearErrors()
      this.form.$busy = true

      const admin = new Admin(this.form.$data())

      try {
        const data = await admin.save()

        this.updateAuthUser(data)

        this.form.$busy = false

        this.showSnackbar('Profile updated!', 'success')
      } catch (err) {
        this.form.$busy = false

        if (err.response.status === 422) {
          this.form.$setErrors(err.response.data.errors)
        }
      }
    },
  },

  watch: {
    authUser() {
      this.propulateForm()
    },
  },
}
</script>
